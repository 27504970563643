
import { Component, OnInit, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
// Datepicker date converter
import * as moment from 'moment';
//
import { Angular2Csv } from 'angular2-csv';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ObligoService } from '../../services/obligo.service';
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CSVService } from '../../services/csv.service';
import { ContactService } from '../../services/contact.service';
import { DebtorAccountService } from '../../services/debtor-account.service';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-open-limit-requests-component',
  templateUrl: './open-limit-requests.component.html',
  styleUrls: ['./open-limit-requests.component.css'],
  animations: [fadeInOut]
})

export class OpenLimitRequestsComponent implements OnInit {
  printerIcon = require("../../assets/images/other/printer.png");
  excelIcon = require("../../assets/images/other/excel-csv.png");

  private user: User = new User();
  openLimitRequestRows = [];
  openLimitRequestRowsCsv = [];
  public pageSilce;
  userrisk: boolean = true;
  debitorAccountsRows = [];
  debUserAccount = [];
  limitRequestParameters = ['ndktdebitnr', 'cname', 'cdktplz', 'cdktort', 'ndktlimitwun', 'ddktdatumwun', 'ndktlimit', 'climkz','ddktlimitab'];
  config: any;
  isDataAvailable: boolean = true;

  //Time
  public now: Date = new Date();

  //CSV Download
  status: any[];  
  openLimitRequestLength;
  fileRows = [];

  // Pagination
  p: number = 1;
  colelction: any[] = this.fileRows;
  dataRefresher: any;

  // table sorting
  key: string = "ddktdatumwun";
  reverse = true;

  //search
  filter;

  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  public setItemsPerPage(event) {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.config.itemsPerPage = event;
      sessionStorage.setItem('seiteDetails', JSON.stringify(this.config.itemsPerPage));
      /* console.log(JSON.parse(sessionStorage.getItem("seiteDetails")));*/
    } else {
      sessionStorage.removeItem('seiteDetails');
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  pageChange(event) {
    this.config.currentPage = event;
  }

  limitRequestItemsSeite: any = [10, 20, 50, 100];
  dynamItemPage;
  pageValue;

  constructor(private accountService: AccountService, private alertService: AlertService, private authService: AuthService,
    private router: Router, private obligoService: ObligoService, private csvService: CSVService, private factorService: ContactService, private debtorAccountService: DebtorAccountService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);

    if (JSON.parse(sessionStorage.getItem("seiteDetails")) > 0) {
      this.dynamItemPage = JSON.parse(sessionStorage.getItem("seiteDetails"));
    } else {
      this.dynamItemPage = 10;
    }

    this.config = {
      currentPage: 1,
      itemsPerPage: this.dynamItemPage,
      totalItems: 0
    };

    for (var i = 0; i < this.limitRequestItemsSeite.length; i++) {
      if (this.limitRequestItemsSeite[i] == this.dynamItemPage) {
        this.setItemsPerPage(this.dynamItemPage);
      }
    }

    this.config.currentPage = 1;
  }

    // load user data
    @Input()
    isGeneralEditor = false;

  ngOnInit(): void {
    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user3' || this.accountService.currentUser.roles[i] === 'user5') {
        this.isDataAvailable = false;
      }
    }
    if (this.isDataAvailable) {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } else {
      this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
    }
  }

    // load user data
    private loadCurrentUserData() {
        this.alertService.startLoadingMessage();

        if (this.canViewAllRoles) {
          this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
        } else {
          this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
        }
    }

    dataCSVUser = [];
    dataFactUser = [];

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
      this.alertService.stopLoadingMessage();
      this.user = user;

      if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }
      }

      if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
        this.user.nfkdid = this.obligoService.getNFKDID();
        if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
          this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
        }
      }

      

      // Webservice calls
      this.factorService.getLimitRequests(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdid, 0)
        .subscribe(data => {
          this.openLimitRequestRows = data;
          console.log(this.openLimitRequestRows);
        });

      this.csvService.getLimitRequestCsv(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdid, 0)
        .subscribe(data => {
          this.openLimitRequestRowsCsv = data;
        });

      this.debtorAccountService.getDebtorAccounts(this.user.nmndid, this.user.nfkdkdnr).subscribe(data => { this.debitorAccountsRows = data; });

      this.csvService.getCsvUser().subscribe(data => {
        this.dataCSVUser = data;
       
        for (var i = 0; i < this.dataCSVUser.length; i++) {
          this.dataCSVUser[i].nfkdid = this.user.nfkdid;
          this.dataCSVUser[i].nfkdkdnr = this.user.nfkdkdnr;
        }
      });

      this.factorService.getUserFactor().subscribe(data => {
        this.dataFactUser = data;
      
        for (var i = 0; i < this.dataFactUser.length; i++) {
          this.dataFactUser[i].nfkdid = this.user.nfkdid;
          this.dataFactUser[i].nfkdkdnr = this.user.nfkdkdnr;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //Pagination
  public onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.openLimitRequestRows.length) { endIndex = this.openLimitRequestRows.length; }
    this.openLimitRequestRows = this.openLimitRequestRows.slice(startIndex, endIndex);
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  goToRisk(ndktdebitnr: number) {
    localStorage.setItem('selectedOptions', JSON.stringify(this.debitorAccountsRows.filter(debitor => debitor.ndktdebitnr === ndktdebitnr)));
    this.router.navigateByUrl('/risk');
  }  

  // Code for download CSV function
  downloadCSV() {
    this.status = ["approved", "rejected", "pending"];

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeNewLines: true,
      headers: ['DebNr.', 'Name', 'Plz', 'Ort', 'Limitwun.Tsd.', 'vom', 'akt.Limit Tsd', 'LiKz', 'Lim.ab']
    };
    new Angular2Csv(this.openLimitRequestRowsCsv, "Offene Limitwünsche", options);
  }

  //PDF print
  print() {
    var printContents = document.getElementById("printable").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print();
    window.close();

    document.body.innerHTML = originalContents;
  } 
}
