import { Component, ViewEncapsulation, OnInit, ViewChildren, AfterViewInit, QueryList } from "@angular/core";
import { Router, NavigationStart } from '@angular/router';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from '../services/alert.service';
import { NotificationService } from "../services/notification.service";
import { AppTranslationService } from "../services/app-translation.service";
import { AccountService } from '../services/account.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { Permission } from '../models/permission.model';
import { LoginComponent } from "../components/login/login.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ContactService } from '../services/contact.service';
import { LimitService } from '../services/limit.service';
import { PopupService } from '../services/popup.service';
import { ComboBoxService } from '../services/combobox-service';
import { ObligoService } from '../services/obligo.service';
import { User } from '../models/user.model';
import { forEach } from "../../../node_modules/@angular-devkit/schematics/src/index";
import { Userdsgvo } from "../models/userdsgvo";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta } from "@angular/platform-browser";
import { LastLimitCount } from "../models/last-limit-count.model";
import { Role } from '../models/role.model';
import { Dsgvo } from "../models/dsgvo";

var alertify: any = require('../assets/scripts/alertify.js');

@Component({
  selector: "app-root",
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit {
  private readonly _baseUrl: string;
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = "Crefo Factoring WebApp 2.0";
  appLogoTop = require("../assets/images/bildmarke/CR-WHITE.png");
  appLogoBottom = require("../assets/images/logo/11_logo-crefofactoring-rgb-cyan.png");
  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;

  adminRoleCheck: boolean = false;

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;

  isDsgvoAccepz: boolean = true;
  dsgvoData: Userdsgvo[] = [];
  lastLimitCount: LastLimitCount[] = [];
  dsgvoRows: Dsgvo[] = [];
  lastLimitDecisions: boolean = false;
  handbuch: boolean = false;
  //Session
  private user: User = new User();
  idUserDsgvo;
  usernmndid: number;
  userPassChangeValue :any;

  adminCheckRoles: boolean = false;
  user1: boolean = false;
  user2: boolean = false;
  user3: boolean = false;
  user4: boolean = false;
  user5: boolean = false;
  canUpload: boolean = false;

  get notificationsTitle() {
    let gT = (key: string) => this.translationService.getTranslation(key);

    if (this.newNotificationCount)
      return `${gT("app.Notifications")} (${this.newNotificationCount} ${gT("app.New")})`;
    else
      return gT("app.Notifications");
  }

  constructor(storageManager: LocalStoreManager, private toastaService: ToastaService, private toastaConfig: ToastaConfig,
    private accountService: AccountService, private alertService: AlertService, private notificationService: NotificationService, private appTitleService: AppTitleService,
    private authService: AuthService, private translationService: AppTranslationService, public configurations: ConfigurationService, public router: Router, private obligoService: ObligoService, private limitService: LimitService, private httpClient: HttpClient, private meta: Meta, private contactService: ContactService, private popupService: PopupService) {
    this._baseUrl = 'api/down/download';
    storageManager.initialiseStorageSyncListener();
    translationService.addLanguages(["en", "de"]);
    translationService.setDefaultLanguage('de');

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-center';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.appTitleService.appName = this.appTitle;
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  ngAfterViewInit() {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }

  onLoginModalShown() {
    this.alertService.showStickyMessage("Sitzung abgelaufen", "Ihre Sitzung ist abgelaufen.Bitte erneut anmelden", MessageSeverity.info);
  }

  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = true;

    if (this.authService.isSessionExpired)
      this.alertService.showStickyMessage("Sitzung abgelaufen", "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an um Ihre Sitzung wieder herzustellen", MessageSeverity.warn);
  }

  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }

  adminSettingsZeigen: boolean = false;
  ngOnInit() {
    
    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 500);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();
   
        if (!this.authService.isSessionExpired) {         
          this.alertService.showMessage("Login", `Willkommen zurück ${this.userName}!`, MessageSeverity.default);     
        } else {
          this.alertService.showStickyMessage("Sitzung abgelaufen", "Ihre Sitzung ist abgelaufen.Bitte erneut anmelden", MessageSeverity.warn);
        }
      }
    }, 500);


    
    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));
    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;    
      
      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
        console.debug(this.accountService.currentUser.roles[1]);
        if (this.accountService.currentUser.roles[1] === 'upload') {
          this.canUpload = true;
        }
        this.loadCurrentUserData();
      } else {
        this.unsubscribeNotifications();
      }
      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage("Sitzung beendet!", "", MessageSeverity.default);
        }
      }, 500);

      
    });

    this.router.events.subscribe(event => {      
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;

        setTimeout(() => {
          if (this.userName === "admin") {
            this.isDsgvoAccepz = true;
          } else if (url == "/" || url == "/home" || url == "/pass-change") {
            this.isDsgvoAccepz = false;
          } else {
          this.isDsgvoAccepz = true;
          }
        }, 500);

        if (url !== url.toLowerCase()) {
          this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
        }
      }     
    });    
  }

  ngOnDestroy() {
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription)
      this.notificationsLoadingSubscription.unsubscribe();
  }


  initNotificationsLoading() {
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically().subscribe(notifications => {
        this.dataLoadingConsecutiveFailurs = 0;
    }, error => {
      this.alertService.logError(error);

      if (this.dataLoadingConsecutiveFailurs++ < 20)
        setTimeout(() => this.initNotificationsLoading(), 1000);
      else
        this.alertService.showStickyMessage("Server Fehler", "Das Laden neuer Benachrichtigungen vom Server ist fehlgeschlagen!", MessageSeverity.error);
    });
  }

  markNotificationsAsRead() {
    let recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true).subscribe(response => {
        for (let n of recentNotifications) {
          n.isRead = true;
        }       
      }, error => {
        this.alertService.logError(error);
        this.alertService.showMessage("Fehler", "Die Markierung gelesener Benachrichtigungen ist fehlgeschlagen", MessageSeverity.error);
      });
    }
  }

  showDialog(dialog: AlertDialog) {
    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || "Cancel"
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);
        break
      case DialogType.confirm:
        alertify.confirm(dialog.message, (e) => {
          if (e) {
            dialog.okCallback();
          } else {
            if (dialog.cancelCallback)
              dialog.cancelCallback();
          }
        });
        break;
      case DialogType.prompt:
        alertify.prompt(dialog.message, (e, val) => {
          if (e) {
            dialog.okCallback(val);
          } else {
            if (dialog.cancelCallback)
              dialog.cancelCallback();
          }
        }, dialog.defaultValue);
        break;
    }
  }

  showToast(message: AlertMessage, isSticky: boolean) {
    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }
      return;
    }

    let toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      timeout: isSticky ? 0 : 4000
    };

    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);
      toastOptions.onRemove = (toast: ToastData) => {
        let index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }

    switch (message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  downloadManual() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }
        this.user.nmndid = this.obligoService.getNMNDID();

        const mediaType = 'application/pdf';
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

        return this.httpClient.get(this._baseUrl + '?' + 'file=' + "handbuch.pdf" + '&' + 'nmndid=' + this.user.nmndid + '&' + 'nfkdkdnr=' + this.user.nfkdkdnr + '&' + 'expectedFolder=' + "none", { headers: httpHeaders, responseType: 'blob' }).subscribe(
          (response) => {
            const blob = new Blob([response], { type: mediaType });
            saveAs(blob, 'handbuch.pdf');
          }, error => {
            this.alertService.showMessage("Kein Handbuch vorhanden.", "", MessageSeverity.info);
            console.log(error);
          });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  // load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user4') {
        this.user4 = true;
      }
      if (this.accountService.currentUser.roles[i] === 'user5') {
        this.user5 = true;
      }
    }

    this.limitService.getLimitCount(user.nmndid).subscribe(data => {
      this.lastLimitCount = data;
      
      if (this.lastLimitCount[0]['anzahl'] > 0) {
        this.lastLimitDecisions = true;
      }
    });

    this.contactService.getDsgvo(user.nmndid).subscribe(data => {
      this.dsgvoRows = data;

      if (this.dsgvoRows[0]['lfachandbuch'] === 1) {
        this.handbuch = true;
      }
    });
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  getUserAdminCheck(username) {
/*    console.log(username);*/
    if ((username === "admin")) {
      this.adminCheckRoles = true;
      this.isDsgvoAccepz = true;
    } else {
      this.adminCheckRoles = false;
    }
 }

  get userName(): string {
    if (this.authService.currentUser != null) {
      this.getUserAdminCheck(this.authService.currentUser.userName);
    } 
    return this.authService.currentUser ? this.authService.currentUser.userName : ""; 
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }

  get canViewAob() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewAobPermission
  }

  get canViewObligo() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewDailyAccountingsPermission
  }

  get canViewDocuments() {    
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewDocumentsPermission
  }

  get canViewLimit() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewLimitPermission
  }

  get canViewOpenLimitRequests() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewOpenLimitRequests
  }

  get canViewContact() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewContact
  }

  get canViewDailyAccountings() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewDailyAccountingsPermission
  }

  get canViewDebtorAccounts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewDebtorAccounts
  }

  get canViewMonthlyAccountings() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewMonthlyAccounts
  }

  get canViewInvoiceList() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewInvoiceList
  }

  get canViewDateiDoku() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewDateiDoku
  }  

  get canViewRecommendation() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewRecommendation
  }

  get canViewRisk() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewRisk
  }

  get canViewOpenItems() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewOpenItems
  }

  get canViewReminders() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewReminders
  }

  get canViewSubmissions() {   
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewSubmissions
  }

  get canViewAccountCreation() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewAccountCreation
  }

  get canViewAccountChange() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewAccountChange
  }

  get canViewLimitChange() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewLimitChange
  }

  get canViewImprint() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewImprint
    }
}
