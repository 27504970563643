
// Web Source: https://www.devglan.com/angular/angular-6-example

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Obligo } from '../models/obligo.model';
import { AuthService } from './auth.service';
import { DebtorAccount } from '../models/debtor-account.model';
import { debug } from 'util';
import { OpenLimitRequest } from '../models/open-limit-request.model';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact.model';
import { Kontosaldenkto } from '../models/kontosaldenKto';
import { ObligoInformation } from '../models/obligo-information.model';
import { User } from '../models/user.model';
import { UserObligo } from '../models/user-obligo.model';
import { AdminFactorRolle } from '../models/admin-factor-role.model';

@Injectable({
  providedIn: 'root',
})
export class ObligoService {

  // obligo info data
  private readonly _baseUrl: string;
  // obligo information
  private readonly _baseUrl2: string;
  // obligo open balances
  private readonly _baseUrl3: string;
  // obligo billing information
  private readonly _baseUrl4: string;
  // obligo account balances
  private readonly _baseUrl5: string;
  // obligo number of accounts
  private readonly _baseUrl6: string;
  // obligo  --> aktivesObligo
  private readonly _baseUrl7: string;
  // obligokonten eines Konzerns
  private readonly _baseUrl8: string;
  // obligo open debtor limit requests
  private readonly _baseUrl9: string;
  //dokumente -> Tagesabrechnung -> GetFirstFileByCreateDate()
  private readonly _apiGetFirstFileByCreatedDateUrl10: string;

  private readonly _apiFileUrl11: string;
  ////contact

  //Obligo -> Kontosalden -> kto(580,583,585)
  private readonly _baseUrl12: string;
  //user
  private readonly _baseUrlUser: string;
 
  _nfkdkdnr: number;
  _nmndid: number;
  _nfkdid: number;
  _allObl :any;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    // obligo info data
    this._baseUrl = 'api/obligo/info/data';

    // obligo information
    this._baseUrl2 = 'api/obligo/information';

    // obligo open balances
    this._baseUrl3 = 'api/obligo/open/balances';

    // obligo billing information
    this._baseUrl4 = 'api/obligo/billing/information';

    // obligo account balances
    this._baseUrl5 = 'api/obligo/account/balances';

    // obligo number of accounts
    this._baseUrl6 = 'api/obligo/number/of/accounts';

    //debitor
    this._baseUrl7 = 'api/debtor/accounts';

    // Konzernobligos
    this._baseUrl8 = 'api/obligo/info/konzern';

    //obligo/open/debitor/limit/requests
    this._baseUrl9 = 'api/obligo/open/debtor/limit/requests';

    //dokumente->Tagesabrechnung
    this._apiGetFirstFileByCreatedDateUrl10 = '/api/show/first/file/by/create/date'; 

    this._apiFileUrl11 = 'api/show/files';

    //kontosalden-> kto
    this._baseUrl12 = 'api/obligo/kontosalden/kto';

    // obligo user
    this._baseUrlUser = 'api/obligo/userObligo';
  }

  getNFKDKDNR() {
    return this._nfkdkdnr;
  }

  setNFKDKDNR(val) {
    this._nfkdkdnr = val;
  }

  getNMNDID() {
    return this._nmndid;
  }

  setNMNDID(val) {
    this._nmndid = val;
  }


  getNFKDID() {
    return this._nfkdid;
  }

  setNFKDID(val) {
    this._nfkdid = val;
  }

  getUserObligo() {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );

      return this.httpClient.get<UserObligo[]>(this._baseUrlUser, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }

  getKontosaldenKtoData(nmndid) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );

      return this.httpClient.get<Kontosaldenkto[]>(this._baseUrl12 + '?' + 'nmndid=' + nmndid, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoInfoData(nmndid, nfkdkdnr, nfkdkonzern) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
      );

      return this.httpClient.get<Obligo[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'nfkdkonzern=' + nfkdkonzern, {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }

  getKonzernObligos(nmndid, nfkdkdnr, nfkdkonzern) {
    try {
      const httpOptions = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );

      return this.httpClient.get<Obligo[]>(this._baseUrl8 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'nfkdkonzern=' + nfkdkonzern, {
        headers: httpOptions
      });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoInformation( nmndid, nfkdkdnr) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
      );
    
      return this.httpClient.get<ObligoInformation[]>(this._baseUrl2 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoOpenBalances(nmndid, nfkdkdnr) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
      );

    this.setNFKDKDNR(nfkdkdnr); 
    

      return this.httpClient.get<Obligo[]>(this._baseUrl3 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr , {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoBillingInformation(nmndid, nfkdkdnr) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<Obligo[]>(this._baseUrl4 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoAccountBalances(nmndid, nfkdkdnr) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<Obligo[]>(this._baseUrl5 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }

  getObligoNumberOfAccounts(nmndid, nfkdkdnr) {
    try {
        const httpOptions = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<Obligo[]>(this._baseUrl6 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {
          headers: httpOptions
        });
    } catch (error) {
      console.log(error);
    }
  }


  getOpenLimitRequests(nmndid, nfkdkdnr, nfkdid) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
      );
      this.setNFKDID(nfkdid);
      this.setNMNDID(nmndid);
      return this.httpClient.get<OpenLimitRequest[]>(this._baseUrl9 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'nfkdid=' + nfkdid, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }
}
