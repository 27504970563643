import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { User } from '../../models/user.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType } from '../../../../node_modules/@angular/common/http';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { DownloadService } from '../../services/download.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ObligoService } from '../../services/obligo.service';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import * as moment from 'moment';
import { fadeInOut } from '../../services/animations';
import { DateiService } from '../../services/datei.service';
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { LimitService } from '../../services/limit.service';
import { Datei } from '../../models/datei';
import { FileUploader } from 'ng2-file-upload';
import { isNumeric } from 'jquery';
import { Eni } from '../../models/eni';
import { Meta } from '@angular/platform-browser';
import { EniMaxGruppenId } from '../../models/enimaxgruppenid.model';
import { PopupService } from '../../services/popup.service';
import { SubmissionPopupComponent } from '../submission-popup/submission-popup.component';

@Component({
  selector: 'app-submissions-all',
  templateUrl: './submissions-all.component.html',
  styleUrls: ['./submissions-all.component.css'],
})

export class SubmissionsAllComponent implements AfterViewInit, OnInit {
  //Download
  public files;
  public zipFiles: string[];
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showUploadError: boolean;
  private expectedFolder: string = "SubmissionFolder";
  private obligoInfoRows = [];
  //private eniValue: any;

  //Attribute for page rendering event if data is available 
  isDataAvailable = true;
  httpCode: any;

  //Session
  private user: User = new User();

  //Begrifflichkeit
  selectedStatusBegriffs = "Anlage Limitprüfungsgebühren";

  //Time
  public now: Date = new Date();
  date: any;

  //submission all daten
  submissionData = [];
  fileRows = [];

  // Pagination
  p: number = 1;
  collection: any[] = this.fileRows;
  dataRefresher: any;
  public pageSilce;

  //File upload new
  uploadForm: FormGroup;
  public uploader: FileUploader;
  eniRows: Array<Eni> = [];
  eniMaxGruppenIdRows: Array<EniMaxGruppenId> = [];
  

 // maxFileSize: 16777216,

  // table sorting
  key: string = "created";
  reverse = true;
  //search
  filter;
  config: any;

  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //Datepicker
  firstFileByCreateDate;
  fromDate = new FormControl(new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000), Validators.required);
  untilDate = new FormControl(new Date(), Validators.required);
  minDate = new Date(1990, 1, 1);
  maxDate = new Date(this.now);

  id: any;
  dateiFileSize;
  dateiFileName: string = "";
  DENIAUFDAT;
  dateiSize;
  cenistatus: string = "NEW-FTP";
  DENIERSTELLDAT;
  DENISTATUSDAT;
  CENIPFADID: string = "F";
  DENIDRUCKDAT;
  NENITYP: number = 0;
  NENIART: number;
  rechnung: boolean = false;
  gutschrift: boolean = false;
  NENIKONTROLLSUM: any;
  kommaformatieren: string;

  //NFKDID für ENI
  obligoSubmission = [];
  obligoSub;

  //Rollen
  userUpload: boolean = false;
  errorMessageCounter: number = 0;

  //Load data before view
  ngAfterViewInit() {
    if (this.authService.isSessionExpired === false) {}
  }

  constructor(private fb: FormBuilder, private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService,
    private downloadService: DownloadService, private authService: AuthService, private router: Router,
    private obligoService: ObligoService, private limitService: LimitService, private dateiService: DateiService, private meta: Meta, private popupService: PopupService) {
    //console.log("nfkdkdnr --constructor---", this.user.nfkdkdnr);
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  // load user data
  @Input()
  isGeneralEditor = false;
  uploadSuccess: boolean;
  gesamtsumme;

  ngOnInit() {
    //Datepicker
    this.date = new Date();
    
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
            if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
              this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
            }
          }
        }

        //Datepicker
        this.date = new Date();

        this.uploadForm = this.fb.group({
          doku: [null, null],
          type: [null, Validators.compose([Validators.required])],
          grosse: [''],      
        });

        //queueLimit: 1

        this.uploader = new FileUploader({
                          disableMultipart: true,
                          isHTML5: true      
                        });

        /*this.uploader.onAfterAddingFile = f => {
          if (this.uploader.queue.length > 1) {
            this.uploader.removeFromQueue(this.uploader.queue[0]);
          }
        };*/

        if (this.user.nmndid == 0 || this.user.nmndid == null) {
          this.user.nmndid = this.obligoService.getNMNDID();
        }     

       } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
       }
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.uploadForm.controls[controlName].hasError(errorName);
  }

  dateiFile;
  dateFile;
  downloadData = [];
  datei: Datei = new Datei();
  geleseneFile: boolean = false;

  public fileDownload(fileName) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        //user
        this.downloadService.getUserDownload().subscribe(data => data);
        this.dateiService.getUserDateis().subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        this.downloadService.downloadFiles(fileName, this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder).subscribe((data) => {
          setTimeout(() => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName;
            link.click();
            this.user.nfkdid = this.user.nfkdid;

            if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
              this.user.nfkdid = this.obligoService.getNFKDID();
              if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                this.user.nfkdid = this.user.nfkdid;
              }
                this.user.nfkdid = this.user.nfkdid;
            }

            this.user.nfkdid = this.user.nfkdid;
            this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
            this.dateiFile = link.download;
            this.dateiFileName = this.dateiFile;
            var anzahl = this.user.nmndid;         //1
            var mndid = anzahl.toString().length;  // "1" -->length => 1              

            if (mndid == 1) {
              this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
            } else {
              this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
            }

            this.datei.NMNDID = this.user.nmndid;
            this.datei.NFKDID = this.user.nfkdid;
            this.datei.CDATEI = this.dateiFile;
            this.datei.DAUFDAT = this.dateFile;
            this.datei.cname = this.dateiFileName;

            this.dateiService.postDatei(this.datei).subscribe((res) => {
              setTimeout(() => {
                this.httpCode = res;
                if (this.httpCode === 500) {
                  this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                } else {
                  this.downloadData.push(res);
                  this.alertService.showMessage("Herunterladen erfolgreich!", "", MessageSeverity.success);
                  this.ngOnInit();
                }
              }, 100);
            });
          }, 100);
        });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (err) {
      console.log(err);
    }
  }

  //Download
  public downloadStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showDownloadError = false;
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showDownloadError = true;
        break;
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewDateiDoku) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  get canViewSumbissions() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.getData();

    if ((this.user.roles.find((r) => r === "upload")) === "upload") {
      this.userUpload = true;
    } else {
      this.userUpload = false;
      if (this.errorMessageCounter === 0) {
        this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
        this.errorMessageCounter++;
      }
      
    }

    this.dateiService.getEni(this.user.nmndid, this.user.nfkdid).subscribe(data => {
      this.eniRows = data;
    });

    this.dateiService.getEniGruppenIdMax().subscribe(data => {
      this.eniMaxGruppenIdRows = data;
    });

    this.obligoService.getObligoInformation(this.user.nmndid, this.user.nfkdkdnr).subscribe(data => {
      this.obligoInfoRows = data;
      console.log(this.obligoInfoRows);
      if (this.obligoInfoRows !== null) {
        localStorage.setItem('obligoInfoRows', JSON.stringify(this.obligoInfoRows));
      }
      
    });   
    
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewDateiDoku() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  rg() {
    this.rechnung = true;
  }

  gs() {
    this.gutschrift = true;
  }

  private fileNameAlreadyExists(fileName: string) {
    var exists = false;
    var fileNameDb = "";
      this.eniRows.forEach(eni => {
        fileNameDb = eni['cenidatei'];
        fileNameDb = fileNameDb.substring(24);
        if (fileNameDb === fileName) {
          exists = true;
        }
      });

    return exists;
  }

  pruefeUserData = [];
  getUploadData = [];
  postUploadValue = [];
  nameitem;
  percentDone: number;

  uploadSwitch(value) {
    this.obligoInfoRows = JSON.parse(localStorage.getItem('obligoInfoRows'));     
    
        if (this.obligoInfoRows[0]['cfkdupload'] === null || this.obligoInfoRows[0]['cfkdupload'] === "") {
          this.uploadSubmit(value);
        } else {
          localStorage.setItem("user", JSON.stringify(this.user));
          this.popupService.setFormGroupValue(value);
          this.popupService.setUploaderQueue(this.uploader.queue);
          this.popupService.setUploadForm(this.uploadForm);
          this.popupService.setEniRows(this.eniRows);
          this.popupService.setEniMaxGruppenIdRows(this.eniMaxGruppenIdRows);
          this.popupService.setGutschrift(this.gutschrift);
          this.popupService.setRechnung(this.rechnung);
          this.popupService.openPopup(SubmissionPopupComponent);
      }    
  }

  uploadSubmit({ value }: { value: Eni }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        //user
        this.dateiService.getUserUpload().subscribe(data => {
          this.pruefeUserData = data;
        });

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
          this.user.nfkdid = this.obligoService.getNFKDID();
          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
            this.user.nfkdid = this.user.nfkdid;
          }
          this.user.nfkdid = this.user.nfkdid;
        }

        for (let i = 0; i < this.uploader.queue.length; i++) {       
            let fileItem = this.uploader.queue[i]._file;
            if (fileItem.size > 16777216) {
              this.alertService.showMessage("Die Datei überschreitet die Maximalgröße von 16 MB!", "", MessageSeverity.warn);
              return;
            }        
        }

        for (let j = 0; j < this.uploader.queue.length; j++) {
          
          let fileItem = this.uploader.queue[j]._file;
          this.dateiFileName = fileItem.name;
          var fileNameLowerCase = fileItem.name.toLowerCase();
          if (fileNameLowerCase.endsWith(".pdf") || fileNameLowerCase.endsWith(".csv") || fileNameLowerCase.endsWith(".txt") ||
            fileNameLowerCase.endsWith(".xls") || fileNameLowerCase.endsWith(".xlsx") || fileNameLowerCase.endsWith(".zip") ||
            fileNameLowerCase.endsWith(".doc") || fileNameLowerCase.endsWith(".docx") || fileNameLowerCase.endsWith(".tif") ||
            fileNameLowerCase.endsWith(".jpg") || fileNameLowerCase.endsWith(".png")) {

            let data = new FormData();
            data.append('file', fileItem);
            data.append('fileSeq', 'seq' + j);
            data.append('dataType', this.uploadForm.controls.type.value);      

            this.dateiService.postUploadFile(this.user.nmndid, this.user.nfkdkdnr, data).subscribe(data => {
              this.postUploadValue = data;
            });

            var anzahl = this.user.nmndid;
            var mndid = anzahl;//.toString().length;//  "1" -->length => 1
            var mn = anzahl.toString().length;

          
            var fileNameDb = "";       
            var i = 2;

            do {
              this.eniRows.forEach(eni => {
                fileNameDb = eni['cenidatei'];
                fileNameDb = fileNameDb.substring(24);

                if (fileNameDb === this.dateiFileName) {
                  var fileName = this.dateiFileName.split('.');
                  if (i > 2) {
                    fileName[0] = fileName[0].substring(0, fileName[0].length - 3);
                  }
                  this.dateiFileName = fileName[0] + "(" + i + ")." + fileName[1];
                  i++;
                }
              });
            } while (this.fileNameAlreadyExists(this.dateiFileName));
          
            this.DENIAUFDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
            this.DENIERSTELLDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
            this.DENIDRUCKDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
            this.DENISTATUSDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
            this.dateiFileSize = fileItem.size;

            if (this.rechnung) {
              this.NENIART = 1;
            }

            if (this.gutschrift) {
              this.NENIART = 2;
            }

            if (mn == 1) {
              this.dateiFileName = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
            } else {
              this.dateiFileName = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
            }

            this.NENIKONTROLLSUM = this.uploadForm.value.grosse;
            this.kommaformatieren = this.NENIKONTROLLSUM.toString();
            this.kommaformatieren = this.kommaformatieren.trim();
            this.NENIKONTROLLSUM = this.kommaformatieren.replace(/,/g, '.');

            value.NMNDID = this.user.nmndid;
            value.NFKDID = this.user.nfkdid;
            value.CENIDATEI = this.dateiFileName;
            value.DENIAUFDAT = this.DENIAUFDAT;
            value.CENISTATUS = this.cenistatus;
            value.NENIFILESIZE = this.dateiFileSize;
            value.DENIERSTELLDAT = this.DENIERSTELLDAT;
            value.DENISTATUSDAT = this.DENISTATUSDAT;
            value.CENIPFADID = this.CENIPFADID;
            value.DENIDRUCKDAT = this.DENIDRUCKDAT;
            value.NENITYP = this.NENITYP;
            value.NENIART = this.NENIART;
            value.NENIKONTROLLSUM = this.NENIKONTROLLSUM;
            value.NENIGRUPPENID = this.eniMaxGruppenIdRows[0]['nenigruppenidmax'];

          

            if (!isNumeric(value.NENIKONTROLLSUM) || value.NENIKONTROLLSUM === null || value.NENIKONTROLLSUM <= 0) {
              this.alertService.showMessage("Die Gesamtsumme in EUR [0-9] muss angegeben werden!", "", MessageSeverity.error);
              return;
            } else {
              this.dateiService.postCreationEniFile(value).subscribe(res => {
                this.httpCode = res;

                if (this.httpCode === 500) {
                  this.alertService.showMessage("Upload Fehlerhaft!", "", MessageSeverity.warn);
                } else {
                  this.submissionData.push(res);
                  this.alertService.showMessage("Upload erfolgreich!", "", MessageSeverity.success);
                  this.ngOnInit();
                }
              });
            }
          } else {
            this.alertService.showMessage("Die Datei '" + this.dateiFileName + "' ist nicht erlaubt. Es dürfen nur Dateien vom Typ .pdf, .csv, .txt, .xls, .xlsx, .zip, .doc, .docx, .tif, .jpg und .png hochgeladen werden.", "", MessageSeverity.error);
            return;
          }
        }  
        this.uploader.clearQueue();
      } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
       }
    } catch (err) {
      console.log(err);
    }
  }

  clear() {
    this.uploadForm.get('grosse').reset();
  }

  getAllDateiFiles = [];
  nameTages = [];
  pruefeRead: boolean;
  dataFiles;

  //gibt alle Dateien zurück
  getData() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        //user
        this.downloadService.getUserDownload().subscribe(data => data);        
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }       

        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
          this.user.nfkdid = this.obligoService.getNFKDID();
          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
            this.user.nfkdid = this.user.nfkdid;
          }
          this.user.nfkdid = this.user.nfkdid;
        }

        //alle Dateien im Tagesabrechnungen
        this.downloadService.getFiles(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder).subscribe(data => {
          this.files = data;
      
          //alle gespeicherte Dateien
          this.dateiService.getSaveDateis(this.user.nmndid, this.user.nfkdid).subscribe(data => {
            this.getAllDateiFiles = data;

            // descending
            this.getAllDateiFiles = this.getAllDateiFiles.sort((a, b) => b.daufdat - a.daufdat);

            for (var i = 0; i < this.files.length; i++) {
              for (var j = 0; j < this.getAllDateiFiles.length; j++) {
                if (this.files[i].name == this.getAllDateiFiles[j].cname && this.user.nfkdid == this.getAllDateiFiles[j].nfkdid && this.user.nmndid == this.getAllDateiFiles[j].nmndid) {
                  this.files[i].read = this.getAllDateiFiles[j].daufdat;
                  break;
                }
              }
            }
          });
        });
      }
    } catch (error) {
      console.log(error);
    } 
  }

  getFirstFileCreateDate() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        //user
        this.downloadService.getUserDownload().subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        //Show first file by create date
        this.downloadService.getFirstFileByCreateDate(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder).subscribe(data => {
            this.firstFileByCreateDate = data;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  downloadZip() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        //user
        this.downloadService.getUserDownload().subscribe(data => data);
        this.dateiService.getUserDateis().subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        this.downloadService.createZipFile(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder, moment(this.fromDate.value).format('YYYY-MM-DD'), moment(this.untilDate.value).format('YYYY-MM-DD')).subscribe(data => {
          setTimeout(() => {

          data;

          if (this.user.nmndid === null || this.user.nmndid <= 0) {
            if (this.user.nfkdkdnr === null || this.user.nfkdkdnr <= 0) {
              this.alertService.showMessage("Service zurzeit nicht verfügbar!!", "", MessageSeverity.warn);
            }
          } else {
            if (data.size !== 0 || data.size > 0) {
            //
            //console.log('download reminders files in zip from: ' + moment(this.fromDate.value).format('YYYY-MM-DD') + "\n" +
            //  'download reminders files in zip until: ' + moment(this.untilDate.value).format('YYYY-MM-DD'));

            return this.downloadService.createZipFile(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder, moment(this.fromDate.value).format('YYYY-MM-DD'), moment(this.untilDate.value).format('YYYY-MM-DD')).subscribe(
              res => {
                const url = window.URL.createObjectURL(res);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = "Einreichungen_" + moment(this.fromDate.value).format('YYYY-MM-DD') + "_" + moment(this.untilDate.value).format('YYYY-MM-DD') + "_" + this.user.nfkdkdnr;
                a.click();                      
              }, error => {
                /* console.log('download error:', JSON.stringify(error));*/
              }, () => {
                /* console.log('Completed file download.')*/
                this.alertService.showMessage("Herunterladen Zip erfolgreich!", "", MessageSeverity.success);
                this.downloadService.getFiles(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder).subscribe(result => {
                this.files = result;

                //VON
                var from = moment(this.fromDate.value).format('YYYY-MM-DD');
                from.trim();

                var fromJahr = from.slice(0, 4);
                var fromMonat = from.slice(5, 7);
                var fromDatum = from.slice(8, 10);

                var fromJ = Number(fromJahr);
                var fromM = Number(fromMonat);
                var fromD = Number(fromDatum);

                  //BIS

                  var until = moment(this.untilDate.value).format('YYYY-MM-DD');

                  until.trim();

                  var untilJahr = until.slice(0, 4);
                  var untilMonat = until.slice(5, 7);
                  var untilDatum = until.slice(8, 10);
                  var untilJ = Number(untilJahr);
                  var untilM = Number(untilMonat);
                  var untilD = Number(untilDatum);

                  for (var i = 0; i < this.files.length; i++) {
                    //DateiName
                    var nameDatei = this.files[i].name;
                    nameDatei.trim();

                    var nameY = nameDatei.slice(0, 4);
                    var nameM = nameDatei.slice(4, 6);
                    var nameD = nameDatei.slice(6, 8);

                    if (!isNaN(Number(nameD)) && !isNaN(Number(nameM)) && !isNaN(Number(nameY))) {
                      var nameJahr = Number(nameY);
                      var nameMonat = Number(nameM);
                      var nameDatum = Number(nameD);

                      if (fromJ <= nameJahr && nameJahr <= untilJ && fromM <= nameMonat && nameMonat <= untilM && fromD <= nameDatum && nameDatum <= untilD) {
                        this.user.nfkdid = this.user.nfkdid;

                        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
                          this.user.nfkdid = this.obligoService.getNFKDID();
                          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                            this.user.nfkdid = this.user.nfkdid;
                          }
                          this.user.nfkdid = this.user.nfkdid;
                        }

                        this.user.nfkdid = this.user.nfkdid;
                        this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
                        this.dateiFile = this.files[i].name;
                        this.dateiFileName = this.files[i].name;
                        var anzahl = this.user.nmndid;         //1
                        var mndid = anzahl.toString().length;  // "1" -->length => 1                                

                        if (mndid == 1) {
                          this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
                        } else {
                          this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
                        }

                        this.datei.NMNDID = this.user.nmndid;
                        this.datei.NFKDID = this.user.nfkdid;
                        this.datei.CDATEI = this.dateiFile;
                        this.datei.DAUFDAT = this.dateFile;
                        this.datei.cname = this.dateiFileName;

                        this.dateiService.postDatei(this.datei).subscribe((res) => {
                          setTimeout(() => {
                            this.httpCode = res;

                            if (this.httpCode === 500) {
                              this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                            } else {
                              this.downloadData.push(res);                                      
                            }
                          }, 100);
                        });
                      }
                    } else {
                      //ErstellDatum
                      var erstellDatum = moment(this.files[i].created).format('YYYY-MM-DD');
                      erstellDatum.trim();

                      var erstellJahr = erstellDatum.slice(0, 4);
                      var erstellMonat = erstellDatum.slice(5, 7);
                      var erstellDatum = erstellDatum.slice(8, 10);

                      var erstellJ = Number(erstellJahr);
                      var erstellM = Number(erstellMonat);
                      var erstellD = Number(erstellDatum);

                      if (fromJ <= erstellJ && erstellJ <= untilJ && fromM <= erstellM && erstellM <= untilM && fromD <= erstellD && erstellD <= untilD) {
                        this.user.nfkdid = this.user.nfkdid;

                        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
                          this.user.nfkdid = this.obligoService.getNFKDID();
                          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                            this.user.nfkdid = this.user.nfkdid;
                          }
                          this.user.nfkdid = this.user.nfkdid;
                        }

                        this.user.nfkdid = this.user.nfkdid;
                        this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
                        this.dateiFile = this.files[i].name;
                        this.dateiFileName = this.files[i].name;
                        var anzahl = this.user.nmndid;         //1
                        var mndid = anzahl.toString().length;  // "1" -->length => 1
                                
                        if (mndid == 1) {
                          this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
                        } else {
                          this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFile;
                        }

                        this.datei.NMNDID = this.user.nmndid;
                        this.datei.NFKDID = this.user.nfkdid;
                        this.datei.CDATEI = this.dateiFile;
                        this.datei.DAUFDAT = this.dateFile;
                        this.datei.cname = this.dateiFileName;

                        this.dateiService.postDatei(this.datei).subscribe((res) => {
                          setTimeout(() => {
                            this.httpCode = res;

                            if (this.httpCode === 500) {
                              this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                            } else {
                              this.downloadData.push(res);                                      
                            }
                          }, 100);
                        });
                      }
                    }
                  }
                });
                this.ngOnInit();
              });
            } else if (data.size === 0) {
              this.alertService.showMessage("Keine Dateien vohanden um als Zip-Archiv heruntergeladen zu werden!", "", MessageSeverity.warn);
            }
          }
        }, 100);
      });
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  } catch (error) {
    console.log(error);
  }
  }
}
