import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Eni } from '../models/eni';
import { AuthService } from '../services/auth.service';
import { DateiService } from '../services/datei.service';
import { User } from '../models/user.model';
import { AlertService, MessageSeverity } from '../services/alert.service';
import { AccountService } from '../services/account.service';
import { Permission } from '../models/permission.model';
import { Role } from '../models/role.model';
import { ObligoService } from '../services/obligo.service';
import { EniMaxGruppenId } from '../models/enimaxgruppenid.model';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { isNumeric } from 'jquery';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private overlayRef: OverlayRef | null = null;
  pruefeUserData = [];
  private user: User = new User();
  userUpload: boolean = false;
  errorMessageCounter: number = 0;
  eniRows: Array<Eni> = [];
  eniMaxGruppenIdRows: Array<EniMaxGruppenId> = [];
  public uploader: FileUploader;
  uploadForm: FormGroup;
  postUploadValue = [];
  dateiFileName: string = "";
  DENIAUFDAT;
  DENIERSTELLDAT;
  DENIDRUCKDAT;
  DENISTATUSDAT;
  public now: Date = new Date();
  dateiFileSize;
  rechnung: boolean = false;
  gutschrift: boolean = false;
  NENIART: number;
  NENIKONTROLLSUM: any;
  kommaformatieren: string;
  cenistatus: string = "NEW-FTP";
  CENIPFADID: string = "F";
  NENITYP: number = 0;
  httpCode: any;
  formGroupValue: any;
  submissionData = [];

  constructor(private fb: FormBuilder, private overlay: Overlay, private authService: AuthService, private dateiService: DateiService, private alertService: AlertService, private accountService: AccountService, private obligoService: ObligoService, private router: Router) {
    this.uploadForm = this.fb.group({
      doku: [null, null],
      type: [null, Validators.compose([Validators.required])],
      grosse: [''],
    });

    this.uploader = new FileUploader({
      disableMultipart: true,
      isHTML5: true
    });   
  }

  private ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      // load user data

      this.loadCurrentUserData();
      if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }
      }

      

      if (this.user.nmndid == 0 || this.user.nmndid == null) {
        this.user.nmndid = this.obligoService.getNMNDID();
      }

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  openPopup(component: any) {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      width: '300px',
      height: '200px'
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    const popupPortal = new ComponentPortal(component);
    this.overlayRef.attach(popupPortal);
  }

  closePopup() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null; // Setze die overlayRef-Variable zurück
    }
  }

  setFormGroupValue(value) {
    this.formGroupValue = value;
  }

  setUploaderQueue(value) {
    this.uploader.queue = value;
  }

  setUploadForm(value) {
    this.uploadForm = value;
  }
  setEniMaxGruppenIdRows(value) {
    this.eniMaxGruppenIdRows = value;
  }

  getFormGroupValue() {
    return this.formGroupValue;
  }

  setGutschrift(value) {
    this.gutschrift = value;
  }

  setRechnung(value) {
    this.rechnung = value;
  }

  setEniRows(value) {
    this.eniRows = value;
  }

  get canViewDateiDoku() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewDateiDoku) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    localStorage.setItem("user", JSON.stringify(this.user));

    if ((this.user.roles.find((r) => r === "upload")) === "upload") {
      this.userUpload = true;
    } else {
      this.userUpload = false;
      if (this.errorMessageCounter === 0) {
        this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
        this.errorMessageCounter++;
      }
    }

    /*this.dateiService.getEni(this.user.nmndid, this.user.nfkdid).subscribe(data => {
      this.eniRows = data;
    });*/ 
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  private fileNameAlreadyExists(fileName: string) {
    var exists = false;
    var fileNameDb = "";
    console.log(this.eniRows);
    this.eniRows.forEach(eni => {
      fileNameDb = eni['cenidatei'];
      fileNameDb = fileNameDb.substring(24);
      if (fileNameDb === fileName) {
        exists = true;
      }
    });
    return exists;
  }

  uploadSubmit({ value }: { value: Eni }) {
    this.ngOnInit();
    
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        //user
        this.dateiService.getUserUpload().subscribe(data => {
          this.pruefeUserData = data;
        });

        
        this.user = JSON.parse(localStorage.getItem("user"));
        
         
        
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
          this.user.nfkdid = this.obligoService.getNFKDID();
          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
            this.user.nfkdid = this.user.nfkdid;
          }
          this.user.nfkdid = this.user.nfkdid;
        }

        for (let i = 0; i < this.uploader.queue.length; i++) {
          let fileItem = this.uploader.queue[i]._file;
          if (fileItem.size > 16777216) {
            this.alertService.showMessage("Die Datei überschreitet die Maximalgröße von 16 MB!", "", MessageSeverity.warn);
            return;
          }
        }
        
        for (let j = 0; j < this.uploader.queue.length; j++) {          
          let fileItem = this.uploader.queue[j]._file;
          this.dateiFileName = fileItem.name;
          var fileNameLowerCase = fileItem.name.toLowerCase();

          if (fileNameLowerCase.endsWith(".pdf") || fileNameLowerCase.endsWith(".csv") || fileNameLowerCase.endsWith(".txt") ||
            fileNameLowerCase.endsWith(".xls") || fileNameLowerCase.endsWith(".xlsx") || fileNameLowerCase.endsWith(".zip") ||
            fileNameLowerCase.endsWith(".doc") || fileNameLowerCase.endsWith(".docx") || fileNameLowerCase.endsWith(".tif") ||
            fileNameLowerCase.endsWith(".jpg") || fileNameLowerCase.endsWith(".png")) {

              let data = new FormData();
              data.append('file', fileItem);
              data.append('fileSeq', 'seq' + j);
              data.append('dataType', this.uploadForm.controls.type.value);

              this.dateiService.postUploadFile(this.user.nmndid, this.user.nfkdkdnr, data).subscribe(data => {
                this.postUploadValue = data;
              });    
          
              var fileNameDb = "";
              var i = 2;
          
              do {
                console.log(this.eniRows);
                this.eniRows.forEach(eni => {
                  fileNameDb = eni['cenidatei'];
                  fileNameDb = fileNameDb.substring(24);

                  if (fileNameDb === this.dateiFileName) {
                    var fileName = this.dateiFileName.split('.');
                    if (i > 2) {
                      fileName[0] = fileName[0].substring(0, fileName[0].length - 3);
                    }
                    this.dateiFileName = fileName[0] + "(" + i + ")." + fileName[1];
                    i++;
                  }
                });
              } while (this.fileNameAlreadyExists(this.dateiFileName));

              this.DENIAUFDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENIERSTELLDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENIDRUCKDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENISTATUSDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.dateiFileSize = fileItem.size;

              if (this.rechnung) {
                this.NENIART = 1;
              }

              if (this.gutschrift) {
                this.NENIART = 2;
              }

              if (this.user.nmndid.toString().length == 1) {
                this.dateiFileName = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
              } else {
                this.dateiFileName = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
              }

              this.NENIKONTROLLSUM = this.uploadForm.value.grosse;
              this.kommaformatieren = this.NENIKONTROLLSUM.toString();
              this.kommaformatieren = this.kommaformatieren.trim();
              this.NENIKONTROLLSUM = this.kommaformatieren.replace(/,/g, '.');
              value.NMNDID = this.user.nmndid;
              value.NFKDID = this.user.nfkdid;
              value.CENIDATEI = this.dateiFileName;
              value.DENIAUFDAT = this.DENIAUFDAT;
              value.CENISTATUS = this.cenistatus;
              value.NENIFILESIZE = this.dateiFileSize;
              value.DENIERSTELLDAT = this.DENIERSTELLDAT;
              value.DENISTATUSDAT = this.DENISTATUSDAT;
              value.CENIPFADID = this.CENIPFADID;
              value.DENIDRUCKDAT = this.DENIDRUCKDAT;
              value.NENITYP = this.NENITYP;
              value.NENIART = this.NENIART;
              value.NENIKONTROLLSUM = this.NENIKONTROLLSUM;
              value.NENIGRUPPENID = this.eniMaxGruppenIdRows[0]['nenigruppenidmax'];         
          
            
              if (!isNumeric(value.NENIKONTROLLSUM) || value.NENIKONTROLLSUM === null || value.NENIKONTROLLSUM <= 0) {
                this.alertService.showMessage("Die Gesamtsumme in EUR [0-9] muss angegeben werden!", "", MessageSeverity.error);
                return;
              } else {
                this.dateiService.postCreationEniFile(value).subscribe(res => {
                  this.httpCode = res;
                
                  if (this.httpCode === 500) {
                    this.alertService.showMessage("Upload Fehlerhaft!", "", MessageSeverity.warn);
                  } else {
                    this.submissionData.push(res);
                    this.alertService.showMessage("Upload erfolgreich!", "", MessageSeverity.success);
                    this.ngOnInit();
                  }
                });
            }
          } else {
            this.alertService.showMessage("Die Datei '" + this.dateiFileName + "' ist nicht erlaubt. Es dürfen nur Dateien vom Typ .pdf, .csv, .txt, .xls, .xlsx, .zip, .doc, .docx, .tif, .jpg und .png hochgeladen werden.", "", MessageSeverity.error);
            return;
          }
        }
        this.uploader.clearQueue();
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (err) {
      console.log(err);
    }
  }

}
