
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from '../../models/user-login.model';
import { User } from "../../models/user.model";
import { Router } from "@angular/router";
import { AccountService } from "../../services/account.service";
import { nullableTypeAnnotation } from "../../../../node_modules/@babel/types/lib/index";
import { ActivatedRoute } from "../../../../node_modules/@angular/router/index";
import { Permission } from "../../models/permission.model";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;
  private isSaving = false;

  @Input()
  isModal = false;

  constructor(private alertService: AlertService, private authService: AuthService, private configurations: ConfigurationService, private router: Router, private accountService: AccountService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }
    
  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription)
      this.loginStatusSubscription.unsubscribe();
  }

  getShouldRedirect() {   
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  private user: User = new User();
  userCheckData:number = 0;

  login() {
    try {
      this.isLoading = true;
      this.alertService.startLoadingMessage("", "Anmeldeversuch...");
      this.authService.login(this.userLogin.email, this.userLogin.password, this.userLogin.rememberMe)
        .subscribe(
          user => {
            setTimeout(() => {
              this.alertService.stopLoadingMessage();
              this.isLoading = true;
              this.reset();

              if (!this.isModal) {
                this.alertService.showMessage("Login", `Willkommen ${user.userName}!`, MessageSeverity.success);
              } else {
                this.alertService.showMessage("Login", `Sitzung für ${user.userName} wiederhergestellt!`, MessageSeverity.success);
                this.alertService.showStickyMessage("Session Restored", "F&uuml;hren Sie Ihre letzte Aktion erneut durch", MessageSeverity.default);
                this.closeModal();
              }
              this.router.navigateByUrl('/');
            }, 500);          
          },        

        error => {
          this.alertService.stopLoadingMessage();
          this.isLoading = false;

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            this.offerAlternateHost();           
          } else {
            let errorMessage = Utilities.findHttpResponseMessage("error_description", error);          

            if (errorMessage) {
              this.alertService.showStickyMessage("Fehler w&auml;hrend der Anmeldung", errorMessage, MessageSeverity.error, error);          
           
              if (!error.ok) {
                this.userCheckData++;
                if (this.userCheckData == 10) {}
              }
            } else {
              this.alertService.showStickyMessage("Fehler w&auml;hrend der Anmeldung", "Ein unerwarteter Fehler w&auml;hrend der Anmeldung ist aufgetreten. Bitte versuchen Sie es sp&auml;ter nochmal.\nError: " + Utilities.getResponseBody(error), MessageSeverity.error, error);
            }
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
      this.alertService.stopLoadingMessage();
    } catch (error) {
      console.log(error);
    }
  }

  offerAlternateHost() {
    if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {
      this.alertService.showDialog("Sehr geehrte Benutzerin, sehr geehrter Benutzer!\nDer Backendservice scheint nicht ordnungsgem&auml;&amp; zu funktionieren...\n" +
        "Bitte kontaktieren Sie den Administrator.",
        DialogType.prompt,
        (value: string) => {
          this.configurations.baseUrl = value;
          this.alertService.showStickyMessage("Web API Änderungen!", "Die Web API wurde wie folgt geändert: " + value, MessageSeverity.warn);
        },
        null,
        null,
        null,
        this.configurations.fallbackBaseUrl);
    }
  }

  reset() {
    this.formResetToggle = true;

    setTimeout(() => {
      this.formResetToggle = true;
    },500);
  }
}
