
import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { MessageSeverity, AlertService } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { ComboBoxService } from '../../services/combobox-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActiveObligo } from '../../models/active-obligo.model';
import { LimitService } from '../../services/limit.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ObligoService } from '../../services/obligo.service';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-limit-component',
  templateUrl: './limit.component.html',
  styleUrls: ['./limit.component.css'],
  animations: [fadeInOut]
})
export class LimitComponent {

  private user: User = new User();

  limit: FormGroup;
  obligoInfoDataRows = [];
  isDataAvailable: boolean = true;

  //ComboBox list
  activeObligoDataRows = [];
  accountCreationMgPlz = [];
  debtorNumberExistsDataRows = [];
  debtorNumberExists: number;
  debtorNumber: number;
  obligoNumber: number;
  activeObligo: number;
  aktObligoLimit: number;
  mitgliedsNummerVorhanden: boolean = false;
  mitgliedsNummer: string = '';
  plzNummerVorhanden: boolean = false;
  plzNummer: string = '';
  nfkdid;

  constructor(private accountService: AccountService,
    private alertService: AlertService, private comboBoxService: ComboBoxService, private limitService: LimitService, private router: Router,
    private authService: AuthService, private obligoService: ObligoService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit(): void {
    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user5') {
        this.isDataAvailable = false;
      }
    }
    if (this.isDataAvailable) {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
     
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }

        this.limit = new FormGroup({
          NFKDKDNR: new FormControl('', Validators.required),
          NDKTDEBITNR: new FormControl('', Validators.required)
        });

      } else {
        this.authService.logout();   
        this.router.navigateByUrl('/login');
      }

    } else {
      this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
    }
  }

  onSelect({ value }: { value: ActiveObligo }) {

    try {
       if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
       
         //Active obligo
         this.comboBoxService.getActiveObligoNumberByUserName(this.user.userName)
           .subscribe(data => {
             this.activeObligoDataRows = data;
        
             for (var i = 0; i < this.activeObligoDataRows.length; i++) {
               if (this.activeObligoDataRows[i].nfkdkdnr === value.NFKDKDNR) {
                 this.user.nfkdid = this.activeObligoDataRows[i].nfkdid;
                 localStorage.setItem("fkdidL", JSON.stringify(this.user.nfkdid));
               }
             }
           });

          this.activeObligo = value.NFKDKDNR;
        /* console.log('Limit Value ---> ',value);*/

         if (value.NFKDKDNR !== null) {
           localStorage.setItem("oblLimit", JSON.stringify(value.NFKDKDNR));       

           // obligo info data
           this.obligoService.getObligoInfoData(this.user.nmndid, value.NFKDKDNR, this.user.nfkdkonzern)
             .subscribe(data => {
               this.obligoInfoDataRows = data;
             /*  console.log(this.obligoInfoDataRows);*/
               for (var i = 0; i < this.obligoInfoDataRows.length; i++) {
                 this.nfkdid = this.obligoInfoDataRows[i].nfkdid;
                 this.limitService.setNFKDID(this.nfkdid);
         /*        console.log(this.limitService.getNFKDID());*/
               }
             });         

           this.limitService.getAccountCreationMgPlz(this.user.nmndid, value.NFKDKDNR)
             .subscribe(data => {
               this.accountCreationMgPlz = data;

               for (var i = 0; i < this.accountCreationMgPlz.length; i++) {
                 this.mitgliedsNummer = this.accountCreationMgPlz[i].mitgliedsNr;
                 this.plzNummer = this.accountCreationMgPlz[i].cfkdplz;

                  if (this.mitgliedsNummer !== null || this.mitgliedsNummer !== '') {
                    this.mitgliedsNummerVorhanden = true;
                 }

                 if (this.plzNummer !== null || this.plzNummer !== '') {
                   this.plzNummerVorhanden = true;
                 }
                }
             });

           this.alertService.showMessage("Aktives Obligo " + this.activeObligo + " ausgewählt.", "", MessageSeverity.default);
          }
       } else {
         this.authService.logout();
         this.router.navigateByUrl('/login');
       }
    } catch (error) {
      console.log(error);
    }
  }

  // Radio button 
  selectedOption: string = '';
  selection: any = [
    'account-creation',
    'account-change',
    'limit-change'
  ];

  radioChangeHandler(event: any) {
    this.selectedOption = event.target.value;
  }

  onSubmit({ value }: { value: ActiveObligo }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        if (this.obligoService.getNFKDKDNR() !== null || this.obligoService.getNFKDKDNR() !== 0) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        }

        localStorage.setItem("oblLimit", JSON.stringify(value.NFKDKDNR));

        if (this.selectedOption !== '') {
          if (value.NDKTDEBITNR > 0) {
            if (this.activeObligo > 0) {
              this.obligoNumber = value.NFKDKDNR;
              this.debtorNumber = value.NDKTDEBITNR;

              switch (this.selectedOption) {
                case "account-creation": {
                  this.checkAccountCreation();                
                  break;
                }
                case "account-change": {
                  this.checkAccountChange();                
                  break;
                }
                case "limit-change": {
                  this.checkLimitChange();                  
                  break;
                }
                default: {                 
                  break;
                }
              }
            } else {
              this.alertService.showMessage("Bitte wählen Sie ein aktives Obligo aus.", "", MessageSeverity.warn);
            }
          } else {
            this.alertService.showMessage("Bitte geben Sie eine Debitornummer (max. 9-stellig) an.", "", MessageSeverity.warn);
          }
        } else {
          this.alertService.showMessage("Bitte wählen Sie eine Option aus.", "", MessageSeverity.warn);
        }
        localStorage.setItem('NDKTDEBITNR', String(value.NDKTDEBITNR));       
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }


  //Check account creation
  checkAccountCreation() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.limitService.getUserLimit().subscribe(data => data);
        this.alertService.showMessage("Kontoeinrichtung ausgewählt!", "", MessageSeverity.default);
        if (this.debtorNumber > 0) {
          if (this.activeObligo > 0) {
            
            //Check debtor number
            this.limitService.getCheckIfDebtorNumberExists(this.user.nmndid, this.activeObligo, this.debtorNumber)
              .subscribe(data => {
                this.debtorNumberExistsDataRows = data;
                this.alertService.showMessage("Die Debitornummer " + this.debtorNumber + " wird überprüft...", "", MessageSeverity.default);
                setTimeout(() => {
                  this.testDebtorNumberNegative();
                }, 500);
              });
          } else {
            this.alertService.showMessage("Bitte wählen Sie ein aktives Obligo aus.", "", MessageSeverity.warn);
          }
        } else {
          this.alertService.showMessage("Bitte geben Sie eine Debitornummer (max. 9-stellig) an.", "", MessageSeverity.warn);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Check account change
  checkAccountChange() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.limitService.getUserLimit().subscribe(data => data);
        this.alertService.showMessage("Kontoänderung ausgewählt!", "", MessageSeverity.default);

        //console.log(
        //  'Kontoänderung Obligo-Nr.:' + ' ' + this.obligoNumber + "\n" +
        //  'Kontoänderung Debitor-Nr.:' + ' ' + this.debtorNumber
        //);

        if (this.debtorNumber > 0) {
          if (this.activeObligo > 0) {

            //Check debtor number
            this.limitService.getCheckIfDebtorNumberExists(this.user.nmndid, this.activeObligo, this.debtorNumber)
              .subscribe(data => {
                this.debtorNumberExistsDataRows = data;
                this.alertService.showMessage("Die Debitornummer " + this.debtorNumber + " wird überprüft...", "", MessageSeverity.default);
                setTimeout(() => {
                  this.testDebtorNumberPositiveAccountChange();
                }, 1500);
              });
          } else {
            this.alertService.showMessage("Bitte wählen Sie ein aktives Obligo aus.", "", MessageSeverity.warn);
          }
        } else {
          this.alertService.showMessage("Bitte geben Sie eine Debitornummer (max. 9-stellig) an.", "", MessageSeverity.warn);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Check limit change
  checkLimitChange() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.limitService.getUserLimit().subscribe(data => data);
        this.alertService.showMessage("Limit&auml;nderung ausgewählt!", "", MessageSeverity.default);

        //console.log(
        //  'Limit&auml;nderung Obligo-Nr.:' + ' ' + this.obligoNumber + "\n" +
        //  'Limit&auml;nderung Debitor-Nr.:' + ' ' + this.debtorNumber
        //);

        if (this.debtorNumber > 0) {
          if (this.activeObligo > 0) {
            //Check debtor number
            this.limitService.getCheckIfDebtorNumberExists(this.user.nmndid, this.activeObligo, this.debtorNumber)
              .subscribe(data => {
                this.debtorNumberExistsDataRows = data;
                this.alertService.showMessage("Die Debitornummer " + this.debtorNumber + " wird überprüft...", "", MessageSeverity.default);
                setTimeout(() => {
                  this.testDebtorNumberPositiveLimitChange();
                }, 1500);
              });
          } else {
            this.alertService.showMessage("Bitte wählen Sie ein aktives Obligo aus.", "", MessageSeverity.warn);
          }
        } else {
          this.alertService.showMessage("Bitte geben Sie eine Debitornummer (max. 9-stellig) an.", "", MessageSeverity.warn);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }


  testDebtorNumberNegative() {  
    if (this.debtorNumberExistsDataRows.length > 0) {
      this.alertService.showMessage("Bitte geben Sie eine andere Debitorkontonummer an. Es ist bereits ein Debitor mit der Nummer " + this.debtorNumber + " bei uns vorhanden.", "", MessageSeverity.error);
    }

    if (this.debtorNumberExistsDataRows.length === 0) {
      this.alertService.showMessage("Es ist noch kein Debitor mit der Nummer " + this.debtorNumber + " für das aktive Obligo " + this.activeObligo + " bei uns vorhanden. Sie werden weiter geleitet...", "", MessageSeverity.info);
      setTimeout(() => {
        this.router.navigateByUrl('/account-creation');
      }, 1000);
    }
  }

  testDebtorNumberPositiveAccountChange() {
    if (this.debtorNumberExistsDataRows.length > 0) {
      this.alertService.showMessage("Es ist bereits ein Debitor mit der Nummer " + this.debtorNumber + " bei uns vorhanden. Sie werden weiter geleitet...", "", MessageSeverity.info);

      setTimeout(() => {
        this.router.navigateByUrl('/account-change');
      }, 1000);
    }


    if (this.debtorNumberExistsDataRows.length === 0) {
      this.alertService.showMessage("Bitte geben Sie eine andere Debitorkontonummer an. Es ist kein Debitor mit der Nummer " + this.debtorNumber + " für das aktive Obligo " + this.activeObligo + " bei uns vorhanden.", "", MessageSeverity.error);
    }
  }

  testDebtorNumberPositiveLimitChange() {   
    if (this.debtorNumberExistsDataRows.length > 0) {
      this.alertService.showMessage("Es ist bereits ein Debitor mit der Nummer " + this.debtorNumber + " bei uns vorhanden. Sie werden weiter geleitet...", "", MessageSeverity.info);

      setTimeout(() => {
        this.router.navigateByUrl('/limit-change');
      }, 1000);
    }

    if (this.debtorNumberExistsDataRows.length === 0) {
      this.alertService.showMessage("Bitte geben Sie eine andere Debitorkontonummer an. Es ist kein Debitor mit der Nummer " + this.debtorNumber + " für das aktive Obligo " + this.activeObligo + " bei uns vorhanden.", "", MessageSeverity.error);
    }
  }


  //Load user data
  private loadCurrentUserData() {  
    this.alertService.startLoadingMessage();
    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }
 
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {     
      this.alertService.stopLoadingMessage();
      this.user = user;
      this.limitService.getUserLimit().subscribe(data => data);
      this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();

      if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
        this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
      }

     /* console.log( "Obligo--",this.user.nfkdkdnr );*/

      //Webservice calls
      // obligo info data
      this.obligoService.getObligoInfoData(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdkonzern)
        .subscribe(data => {
          this.obligoInfoDataRows = data;

          for (var i = 0; i < this.obligoInfoDataRows.length; i++) {
            if (this.obligoInfoDataRows[i].nfkdkdnr === this.user.nfkdkdnr) {
              this.activeObligo = this.obligoInfoDataRows[i].nfkdkdnr;
            }

            this.activeObligo = this.obligoInfoDataRows[i].nfkdkdnr;
            this.nfkdid = this.obligoInfoDataRows[i].nfkdid;
            this.user.nfkdkdnr = this.activeObligo;
            localStorage.setItem("obl", JSON.stringify(this.activeObligo));
          }
        });

      //Active obligo
      this.comboBoxService.getActiveObligoNumberByUserName(this.user.userName)
        .subscribe(data => {  
          this.activeObligoDataRows = data;         
      
          for (var i = 0; i < this.activeObligoDataRows.length; i++) {
            if (this.activeObligoDataRows[i].nfkdkdnr === this.user.nfkdkdnr) {
              this.user.nfkdid = this.activeObligoDataRows[i].nfkdid;
              localStorage.setItem("fkdidL", JSON.stringify(this.user.nfkdid));
            }
          }  
        });

      //E-Crefo Mitgliedsnummer und PLZ
      this.limitService.getAccountCreationMgPlz(this.user.nmndid, JSON.parse(localStorage.getItem("obl")))
        .subscribe(data => {
          this.accountCreationMgPlz = data;

          for (var i = 0; i < this.accountCreationMgPlz.length; i++) {
            this.mitgliedsNummer = this.accountCreationMgPlz[i].mitgliedsNr;
            this.plzNummer = this.accountCreationMgPlz[i].cfkdplz;

            //console.log(this.mitgliedsNummer);
            //console.log(this.plzNummer);

            if (this.mitgliedsNummer !== null) {
              this.mitgliedsNummerVorhanden = true;
            }
            if (this.plzNummer !== null) {
              this.plzNummerVorhanden = true;
            }

            localStorage.setItem("mitgliedsNummer", JSON.stringify(this.mitgliedsNummerVorhanden));

            JSON.parse(localStorage.getItem("mitgliedsNummer"));

            localStorage.setItem("plzNummer", JSON.stringify(this.plzNummerVorhanden));

            JSON.parse(localStorage.getItem("plzNummer"));

          }
          //console.log('MitgliedsNummerVorhanden', this.mitgliedsNummerVorhanden);
          //console.log('PlzNummerVorhanden', this.plzNummerVorhanden);
          //console.log(this.accountCreationMgPlz);
        });
    } catch (error) {
      console.log(error);
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
