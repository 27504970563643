import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input} from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { ContactService } from '../../services/contact.service';
import { ObligoService } from '../../services/obligo.service';
import { UserInfoComponent } from '../controls/user-info.component';
import { UserEdit } from '../../models/user-edit.model';
import { Utilities } from "../../services/utilities";
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// Datepicker date converter
import * as moment from 'moment';

import { formatDate } from '@angular/common';
import { Userdsgvo } from '../../models/userdsgvo';
import { parseDate } from 'ngx-bootstrap';
import { UserLogin } from '../../models/user-login.model';
import { Meta } from '@angular/platform-browser';

moment.locale('de');
registerLocaleData(localeDe, 'de');

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    animations: [fadeInOut]
})
export class HomeComponent implements OnInit{
  
  dsgvoRows = [];
  checked = false;
  changetext = true; //muss false sein 
  dsgvoAcceptedCreation: FormGroup;
  private isSaving = false;

  // load user data
  @Input()
  isGeneralEditor = false;

  @ViewChild('f')
  private form;

  //Session
  private user: User = new User();
  private userEdit: UserEdit;
  private allRoles: Role[] = [];
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  public formResetToggle = true;

  //DSGVO
  facdsgvoaendat;
  lastDsgvo;
  lastJobTitle;
  checkPass: boolean;
  getIdUser= [];
  aktuellsteDatumFACGelesen;
  aktuellsteDatumASPDsgvoAcceptedGelesen;
  pruefeDSGVOAccepted: boolean = false;
  dsgvoaendat;
  options: FormGroup;
  private showValidationErrors = false;
  private isEditingSelf = false;
  private isEditMode = false;
  private isNewUser = false;

  //Time
  public now: Date = new Date();

  constructor(fb: FormBuilder, private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService, private authService: AuthService,
    private router: Router, private token: EndpointFactory, private dsgvoServise: ContactService, private obligoService: ObligoService, private meta: Meta) {
      this.meta.addTags([
        { name: 'robots', content: 'noindex,follow' }
      ]);
    
    this.options = fb.group({
        checked: false,
      });
  }

  ngOnInit() {

    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
    }
  }

  //Load user data
  private loadCurrentUserData() {

    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }


  /**
   * @param user
   * @param roles
   */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {

    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;

    this.dsgvoServise.getDsgvo(this.user.nmndid)
      .subscribe(data => {
        setTimeout(() => {
          this.dsgvoRows = data;         
       
          for (var i = 0; i < this.dsgvoRows.length; i++) {
            this.facdsgvoaendat = this.dsgvoRows[i].dfacdsgvoaendat;
            this.dsgvoServise.getUserDsgvo(this.user.id, this.user.nmndid).subscribe(dataDSGVO => {
              this.getIdUser = dataDSGVO;
                        
              for (var i = 0; i < this.getIdUser.length; i++) {
                this.lastDsgvo = this.getIdUser[i].dsgvoAccepted;
                this.lastJobTitle = this.getIdUser[i].jobTitle;
                this.checkPass = this.getIdUser[i].changePasswordRequest;
                this.aktuellsteDatumASPDsgvoAcceptedGelesen = this.lastDsgvo;
                this.aktuellsteDatumFACGelesen = this.facdsgvoaendat;

                ////Datums vergleich ====  ASP.NET.USERS -> DsgvoAccepted und FAC -> DFACDSGVOAENDAT
                setTimeout(() => {
                  if (this.aktuellsteDatumFACGelesen > this.aktuellsteDatumASPDsgvoAcceptedGelesen) {             
                    this.pruefeDSGVOAccepted = true;
                    this.alertService.showMessage("", `Alle wichtigen Änderungen zur DSGVO vom \"${moment(this.facdsgvoaendat).format("DD.MM.YYYY")}\"! Bitte akzeptieren Sie !!! `, MessageSeverity.success);
                  }

                  if (this.aktuellsteDatumFACGelesen < this.aktuellsteDatumASPDsgvoAcceptedGelesen) {
                    this.pruefeDSGVOAccepted = false;

                    if (this.checkPass) {
                      this.router.navigateByUrl('/pass-change');
                      this.checked = false;
                    }

                    if (!this.checkPass) {
                      this.router.navigateByUrl('/obligo');
                    }
                  } else {
                    this.pruefeDSGVOAccepted = true;
                  }
                }, 100);
              }                     
            });
          }
        }, 100);
      });
  }
 
  //DSGVO Akzeptieren - cb
  private selectedCheckbox() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if (this.checked) {
          this.isSaving = true;

          if (!this.isGeneralEditor) {
            this.isEditingSelf = true;
            this.userEdit = new UserEdit();
            Object.assign(this.userEdit, this.user);
          }
          else {
            if (!this.userEdit)
              this.userEdit = new UserEdit();
            this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id === this.accountService.currentUser.id : false;
          }
          this.isEditMode = true;
          this.userEdit.dsgvoAccepted = this.now;
          
          this.userEdit.jobTitle = this.lastJobTitle;
          this.dsgvoaendat = new Date(this.userEdit.dsgvoAccepted).toLocaleDateString();

          if (this.isNewUser) {
            this.accountService.newUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));
          }
          else {
            console.log(this.userEdit);
            this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
          }
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  private saveSuccessHelper(user?: User) {
    if (user)
      Object.assign(this.userEdit, user);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.checked = false;
    this.showValidationErrors = false;
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();

    if (this.isGeneralEditor) {
      if (this.isNewUser)
        this.alertService.showMessage("Success", `User \"${this.user.userName}\" was created successfully`, MessageSeverity.success);
      else if (!this.isEditingSelf)
        this.alertService.showMessage("Success", `Changes to user \"${this.user.userName}\" was saved successfully`, MessageSeverity.success);
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage("", "Sie haben Ihre DSGVO erfolgreich akzeptiert!!!", MessageSeverity.success);
    }

    if (this.checkPass) {
      this.router.navigateByUrl('/pass-change');
     
    }
    if (!this.checkPass) {
      this.router.navigateByUrl('/obligo');
    }

    this.isEditMode = false;
    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }

  private saveFailedHelper(error) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Ladefehler", `Benutzerdaten können nicht vom Server abgerufen werden.\r\nFehler: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Ladefehler: Benutzerdaten können nicht vom Server abgerufen werden.\r\nFehler: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
