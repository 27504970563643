import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import 'rxjs/Rx';
import { DebtorAccountService } from '../../services/debtor-account.service';

// Spinner
import { NgxSpinnerService } from 'ngx-spinner';

// alert
import { AlertService, MessageSeverity } from '../../services/alert.service';

// print
import * as jsPDF from 'jspdf';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';
import { Router } from '@angular/router';

// CSV download
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

// Datepicker date converter
import * as moment from 'moment';
import { CSVService } from '../../services/csv.service';

//Pagination
import * as _ from 'underscore';
import { AuthService } from '../../services/auth.service';
import { ActiveObligo } from '../../models/active-obligo.model';

//Pagination - 2
import { ObligoService } from '../../services/obligo.service';
/*import { PageEvent } from '@angular/material/typings/esm5/paginator';*/
import { QueryList, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DebtorAccount } from '../../models/debtor-account.model';
import { DebtorAccountCSV } from '../../models/debtor-account-csv.model';
import { Console } from 'console';


@Component({
  selector: 'app-debtor-accounts-component',
  templateUrl: './debtor-accounts.component.html',
  styleUrls: ['./debtor-accounts.component.css'],
  animations: [fadeInOut]
})

export class DebtorAccountsComponent implements OnInit {

  printerIcon = require("../../assets/images/other/printer.png");
  excelIcon = require("../../assets/images/other/excel-csv.png");

  private user: User = new User();
  public pageSilce;
  allDebitors: number;
  debtorAccountsRows = [];
  testDataRows = [];
  debtorAccountsCSVRows = [];
  debtorAccountParameters = ['cdktdebitnr', 'ndktdebitnr', 'cdktnamE1', 'cdktnamE2', 'cdktstr', 'clndkz', 'cdktplz', 'cdktort', 'cvrbnr', 'cdktfremdnr'];
  isDataAvailable: boolean = true;

  // CSV Download
  status: any[];
  columns = []
  selected = [];

  //Time
  public now: Date = new Date();

  // table sorting
  key: string = "ndktdebitnr";
  reverse = false;

  //search
  filter;

  //Event handler for the select element's change event
  selectedDebtorAccountsQuantity: number;

  selectChangeHandler(event: any) {
    //Update the ui
    this.selectedDebtorAccountsQuantity = event.target.value;
  }

  //Select/unselect all items in data table
  masterSelected: boolean = false;
  checkedList: any;

  // Multiselect
  itemList = [];
  selectedItems = [];
  settings = {};
  showNavigation: boolean;
  config: any;

  public setItemsPerPage(event) {

    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      this.config.itemsPerPage = event;
      sessionStorage.setItem('seiteDetails', JSON.stringify(this.config.itemsPerPage));
      /* console.log(JSON.parse(sessionStorage.getItem("seiteDetails")));*/
    } else {
      sessionStorage.removeItem('seiteDetails');
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }

  }
  pageChange(event) {
    this.config.currentPage = event;
  }

  debAccountItemsSeite: any = [10, 20, 50, 100];
  dynamItemPage;
  pageValue;

  constructor(private alertService: AlertService, private accountService: AccountService,
    private debtorAccountService: DebtorAccountService, private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private router: Router, private csvService: CSVService,
    private authService: AuthService, private obligoService: ObligoService, private meta: Meta) {
      this.meta.addTags([
        { name: 'robots', content: 'noindex,follow' }
      ]);

      if (JSON.parse(sessionStorage.getItem("seiteDetails")) > 0) {
        this.dynamItemPage = JSON.parse(sessionStorage.getItem("seiteDetails"));
      } else {
        this.dynamItemPage = 10;
      }

      this.config = {
        currentPage: 1,
        itemsPerPage: this.dynamItemPage,
        totalItems: 0
      };

      for (var i = 0; i < this.debAccountItemsSeite.length; i++) {
        if (this.debAccountItemsSeite[i] == this.dynamItemPage) {
          this.setItemsPerPage(this.dynamItemPage);
        }
      }

      this.config.currentPage = 1;
      for (let i = 1; i <= 100; i++) {
        this.debtorAccountsRows.push(`item ${i}`);
      }

      this.selectedDebtorAccountsQuantity = 10;

      this.columns = [
        {
          checkboxable: true,
          width: 30
        },
      ]

      // Select/unselect all items in data table
      this.masterSelected = false;
      this.getCheckedItemList();
  }

  /*
  * Select data row by selecting checkbox element
  */
  // Web Source: https://stackoverflow.com/questions/34997128/angular-2-get-values-of-multiple-checked-checkboxes/40166591

  get selectedOptions() {

    this.SendSelectedOptions(); // <=== Send selected debtor accounts to open-items.component

    return this.debtorAccountsRows
      .filter(opt => opt.checked) // <=== Show full row by checkbox selection
  }

  SendSelectedOptions() {
    localStorage.setItem('selectedOptions', JSON.stringify(this.debtorAccountsRows.filter(opt => opt.checked)));
    //console.log(JSON.stringify(this.debtorAccountsRows.filter(opt => opt.checked)));
  }

  

  // Select/unselect all items in data table
  checkedAll() {

    for (let i = 0; i < this.debtorAccountsRows.length; i++) {
      this.debtorAccountsRows[i].checked = this.masterSelected;
    }

    const element = <HTMLInputElement>document.getElementById("isCheckedAllBox");

    if (element.checked) {
      this.alertService.showMessage("Alle" + " " + this.debtorAccountsRows.length + " " + "Debitorenkonten ausgew&auml;hlt!", "", MessageSeverity.warn);
      this.showNavigation = element.checked;
    }

    this.getCheckedItemList();
  }

  selectedAll: any;
  selectAll() {
    for (let i = 0; i < this.debtorAccountsRows.length; i++) {
      this.debtorAccountsRows[i].checked = this.selectedAll;
    }
  }

  checkIfOneSelected() {

    const element = <HTMLInputElement>document.getElementById("isCheckedOneBox");

    if (element.checked) {
      this.showNavigation = element.checked;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];

    for (let i = 0; i < this.debtorAccountsRows.length; i++) {
      if (this.debtorAccountsRows[i].checked) {
        if (this.checkedList.length <= 4000) {
          this.checkedList.push(this.debtorAccountsRows[i]);
        } else {
          this.alertService.showMessage("Mehr als 4000 Debitoren können Sie gleichzeitig nicht auswählen!", "", MessageSeverity.warn);
          break;
        }
      }
    }
    localStorage.setItem('selectedOptions', JSON.stringify(this.checkedList));
  }

  /*
  * GET all debtor accounts from web service 
  */
  // load user data
  @Input()
  isGeneralEditor = false;

  

  ngOnInit() {
    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user4' || this.accountService.currentUser.roles[i] === 'user5') {
        this.isDataAvailable = false;
      }
    }
    if (this.isDataAvailable) {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();

          // Page rendering event if data is available

          if (this.debtorAccountsRows.values.length != null) {
            this.isDataAvailable = true;
          }
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
        }
    } else {
      this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  rolleUserAdminFactory: boolean = false;
  debUserAccount = [];
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {

    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.alertService.stopLoadingMessage();
      this.user = user;

      this.debtorAccountService.getUserDeb().subscribe(data => {
        this.debUserAccount = data;
      });

      if (this.user.nfkdkdnr === 0 || this.user.nfkdkdnr === null) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }
      }

      this.debtorAccountService.getDebtorAccounts(this.user.nmndid, this.user.nfkdkdnr).subscribe(data => {
          this.debtorAccountsRows = data;
          this.allDebitors = this.debtorAccountsRows.length;
        });

      this.csvService.getCsvUser().subscribe(data => data);

      this.csvService.getDebtorAccounts(this.user.nmndid, this.user.nfkdkdnr).subscribe(data => {
          this.debtorAccountsCSVRows = data;
        }
      );
      // Test
      // https://www.angularjswiki.com/angular/how-to-read-local-json-files-in-angular/
    }
  }

  //Paggination
  public onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.debtorAccountsRows.length) { endIndex = this.debtorAccountsRows.length; }
    this.debtorAccountsRows = this.debtorAccountsRows.slice(startIndex, endIndex);
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  // Code for download CSV function
  downloadCSV() {
    this.status = ["approved", "rejected", "pending"];

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeNewLines: true,
      headers: ['Deb.Nr', 'Deb.Name1', 'Deb.Name2', 'Deb.Straße', 'Deb.Land', 'Deb.PLZ', 'Deb.Ort', 'Verbandnummer', 'MFFNr.']
    };

    new Angular2Csv(this.debtorAccountsCSVRows, "Debitorenkonten", options);
  }

  /*
  // print test
  @ViewChild('content') content: ElementRef;
  public downloadPDF() {

    // Source: https://www.youtube.com/watch?v=AdoPkp4KzFA
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#content': function (element, renderer) {
        return true;
      }
    };

    const content = this.content.nativeElement;

    doc.fromHTML(content.innerHTML, 15, 15, {
      'width': 200,
      'elementHandlers': specialElementHandlers
    });

    doc.save('Debitorenkonten.pdf');
  }
  */
  // Print page 
  print() {
    var printContents = document.getElementById("printable").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print();
    window.close();

    document.body.innerHTML = originalContents;
  }
}
