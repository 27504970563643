import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup.service';
//import { ObligoService } from '../../services/obligo.service';

@Component({
  selector: 'app-submission-popup',
  templateUrl: './submission-popup.component.html',
  styleUrls: ['./submission-popup.component.css']
})

export class SubmissionPopupComponent implements OnInit {
  popupText: string;
  private obligoInfoRows = [];

  constructor(private popupService: PopupService) {    
  } 

  ngOnInit() {
    
      this.obligoInfoRows = JSON.parse(localStorage.getItem('obligoInfoRows'));
      this.popupText = this.obligoInfoRows[0]['cfkdupload'];
    
  }

  confirm() {
    this.popupService.uploadSubmit(this.popupService.getFormGroupValue());
    this.popupService.closePopup();
  }

  refuse() {
    this.popupService.closePopup();
  }
}
