// ====================================================
// LAZY LOAD IMPLEMENTATION: https://codeburst.io/how-to-implement-lazy-loading-in-angular-bb2a670b34d
// ====================================================

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { InfoComponent } from './components/info/info.component';
import { AobComponent } from './components/aob/aob.component';
import { ObligoComponent } from "./components/obligo/obligo.component";
import { DocumentsComponent } from './components/documents/documents.component';
import { LimitComponent } from './components/limit/limit.component';
import { ContactComponent } from './components/contact/contact.component';
import { OpenLimitRequestsComponent } from './components/open-limit-requests/open-limit-requests.component';
import { LastLimitDecisionsComponent } from './components/last-limit-decisions/last-limit-decisions.component';
import { DailyAccountingsComponent } from './components/daily-accountings/daily-accountings.component';
import { DebtorAccountsComponent } from './components/debtor-accounts/debtor-accounts.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { MonthlyAccountingsComponent } from './components/monthly-accountings/monthly-accountings.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { RiskComponent } from './components/risk/risk.component';
import { OpenItemsComponent } from './components/open-items/open-items.component';
import { RemindersComponent } from './components/reminders/reminders.component';
import { SubmissionsComponent } from './components/submissions/submissions.component';
import { AccountCreationComponent } from './components/account-creation/account-creation.component';
import { AccountChangeComponent } from './components/account-change/account-change.component';
import { LimitChangeComponent } from './components/limit-change/limit-change.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { SubmissionsAllComponent } from './components/submissions-all/submissions-all.component';
import { KontoCreationSuccessComponent } from './components/konto-creation-success/konto-creation-success.component';
import { KontoChangeSuccessComponent } from './components/konto-change-success/konto-change-success.component';
import { LimitChangeSuccessComponent } from './components/limit-change-success/limit-change-success.component';
import { LoginPassChangeComponent } from './components/login-pass-change/login-pass-change.component';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { AdminCheckComponent } from './components/admin-check/admin-check.component';
import { SubmissionPopupComponent } from './components/submission-popup/submission-popup.component';


const routes: Routes = [

  {
    path: "login", component: LoginComponent,
    data: {
      title: "Anmeldung",
      preload: true
    }
  },


  {
    path: "", component: HomeComponent, canActivate: [AuthGuard],
    data: {
      title: "Home",
      preload: true
    }
  },

  {
    path: "pass-change", component: LoginPassChangeComponent, canActivate: [AuthGuard],
    data: {
      title: "LoginPassChange",
      preload: true
    }
  },
  {
    path: "info", component: InfoComponent, canActivate: [AuthGuard],
    data: {
      title: "allg. Infos",
      preload: true
    },
  },
  {
    path: "aob", component: AobComponent, canActivate: [AuthGuard],
    data: {
      title: "Sonstiges",
      //loadChildren: "./components/aob/aob.module#AobModule",
      preload: true
    },
  },
  {
    path: "contact", component: ContactComponent, canActivate: [AuthGuard],
    data: {
      title: "Kontakt",
      preload: true
    }
  },
  {
    path: "dsgvo", component: DatenschutzComponent, canActivate: [AuthGuard],
    data: {
      title: "Datenschutz",
      preload: true
    }
  },


  {
    path: "obligo", component: ObligoComponent, canActivate: [AuthGuard],
    data: {  
      title: "Obligo",
      preload: true
    }
  },
 

  {
    path: "daily-accountings", component: DailyAccountingsComponent, canActivate: [AuthGuard],
    data: {
      title: "Tagesabrechnungen",
      preload: true
    }
  },
  {
    path: "monthly-accountings", component: MonthlyAccountingsComponent, canActivate: [AuthGuard],
    data: {
      title: "Monatsabrechnungen",
      preload: true
    }
  },
  {
    path: "debtor-accounts", component: DebtorAccountsComponent, canActivate: [AuthGuard],
    data: {
      title: "Debitorenkonten",
      preload: true
    }
  },
  {
    path: "documents", component: DocumentsComponent, canActivate: [AuthGuard],
    data: {
      title: "Dokumente",
      preload: true
    }
  },
  {
    path: "invoice-list", component: InvoiceListComponent, canActivate: [AuthGuard],
    data: {
      title: "Rechnungsliste",
      preload: true
    }
  },
  {
    path: "limit", component: LimitComponent, canActivate: [AuthGuard],
    data: {
      title: "Limit/Kontoänd.",
      preload: true
    }
  },
  {
    path: "open-limit-requests", component: OpenLimitRequestsComponent, canActivate: [AuthGuard],
    data: {
      title: "Off. Limitwün.",
      preload: true
    }
  },
  {
    path: "last-limit-decisions", component: LastLimitDecisionsComponent, canActivate: [AuthGuard],
    data: {
      title: "Letzte Limitentscheidungen",
      preload: true
    }
  },
  {
    path: "recommendation", component: RecommendationComponent, canActivate: [AuthGuard],
    data: {
      title: "Empfehlung",
      preload: true
    }
  },
  {
    path: "risk", component: RiskComponent, canActivate: [AuthGuard],
    data: {
      title: "Risiko",
      preload: true
    }
  },
  {
    path: "open-items", component: OpenItemsComponent, canActivate: [AuthGuard],
    data: {
      title: "Offene Posten",
      preload: true
    }
  },
  {
    path: "reminders", component: RemindersComponent, canActivate: [AuthGuard],
    data: {
      title: "Mahnungen",
      loadChildren: "./components/reminders/reminders.module#RemindersModule",
      preload: true
    }
  },
  {
    path: "submissions", component: SubmissionsComponent, canActivate: [AuthGuard],
    data: {
      title: "Einreichungen",
      loadChildren: "./components/submissions/submissions.module#SubmissionsModule",
      preload: true
    }
  },
  {
    path: "submission-popup", component: SubmissionPopupComponent, canActivate: [AuthGuard],
    data: {
      title: "Einreichungen",
      loadChildren: "./components/submission-popup/submission-popup.module#SubmissionPopupModule",
      preload: true
    }
  },
  {
    path: "submissions-all", component: SubmissionsAllComponent, canActivate: [AuthGuard],
    data: {
      title: "EinreichungenAll",
      loadChildren: "./components/submissions-all/submissions-all.module#SubmissionsAllModule",
      preload: true
    }
  },

  {
    path: "account-creation", component: AccountCreationComponent, canActivate: [AuthGuard],
    data: {
      title: "Kontoeinrichtung",
      loadChildren: "./components/account-creation/account-creation.module#AccountCreationModule",
      preload: true
    }
  },

  {
    path: "account-creation-success", component: KontoCreationSuccessComponent, canActivate: [AuthGuard],
    data: {
      title: "Kontoeinrichtung erfolgreich",
      preload: true
    }
  },


  {
    path: "account-change", component: AccountChangeComponent, canActivate: [AuthGuard],
    data: {
      title: "Kontoänderung",
      loadChildren: "./components/account-change/account-change.module#AccountChangeModule",
      preload: true
    }
  },
  {
    path: "account-change-success", component: KontoChangeSuccessComponent, canActivate: [AuthGuard],
    data: {
      title: "Kontoaenderung erfolgreich",
      preload: true
    }
  },

  {
    path: "limit-change", component: LimitChangeComponent, canActivate: [AuthGuard],
    data: {
      title: "Limitänderung",
      preload: true
    }
  },

  {
    path: "limit-change-success", component: LimitChangeSuccessComponent, canActivate: [AuthGuard],
    data: {
      title: "Limitaenderung erfolgreich",
      preload: true
    }
  },

  {
    path: "imprint", component: ImprintComponent, canActivate: [AuthGuard],
    data: {
      title: "Impressum",
      preload: true
    }
  }, 


  {
    path: "check", component: AdminCheckComponent, canActivate: [AuthGuard],
    data: {
      title: "Check Admin",
      preload: true
    }
  },


 
  {
    path: "settings", component: SettingsComponent, canActivate: [AuthGuard],
    data: {
      title: "Settings",
      preload: true
    }
  },
  { path: "home", redirectTo: "/", pathMatch: "full" }, 
  {
    path: "**", component: NotFoundComponent,
    data: {
      title: "Page Not Found",
      preload: true
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
